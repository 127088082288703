<template>
  <div>
    <ca-header
      heading="KFZ Tarife"
      :column-layout="true"
    >
      <template #buttonSpace>
        <router-link
          class="btn btn-primary"
          :to="{name: 'RateCreate' }"
        >
          Neuen Tarif hinzufügen
        </router-link>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <data-table
        :fields="fields"
        service="rate-list"
        title="Tarife"
        @row-clicked="(item) => $router.push({name: 'RateEdit', params: {rateId: item._id}})"
      />
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import DataTable from '@/components/DataTable/DataTable'
import formatPrice from '../../filters/formatPrice'
export default {
  name: 'RateList',
  components: {
    DataTable,
    CaHeader
  },
  data: () => ({
    fields: [
      {
        key: 'product.hn',
        label: 'HN'

      },
      {
        key: 'un',
        label: 'UN'

      },
      {
        key: 'product.name',
        label: 'Produkt'

      },
      {
        key: 'name',
        label: 'Tarifname'

      },
      {
        key: 'laufzeit',
        label: 'Laufzeit'

      },
      {
        key: 'brutto',
        label: 'Brutto',
        formatter: formatPrice

      },
      {
        key: 'vst',
        label: 'Vst',
        formatter: formatPrice

      },
      {
        key: 'netto',
        label: 'Netto',
        formatter: formatPrice

      },
      {
        key: 'agb.name',
        label: 'GB'
      }
    ]
  })
}
</script>

<style scoped lang="scss">

</style>
